<template>
  <div class="d-flex _bg-default _80w _100vh">
    <div class="_100top pa-3 _full-w">
      <div class="mb-3">
        <v-btn small fab depressed color="green" dark @click="getBack">
          <v-icon>mdi-reply</v-icon>
        </v-btn>
      </div>
      <div v-if="!loading">
        <div v-if="detailAgenda">
          <v-card class="radius-card width__agend">
            <section class="px-5 py-5">
              <div class="d-flex justify-space-between">
                <h3>{{ detailAgenda.data.judul }}</h3>
                <v-chip
                  class="white--text mr-3"
                  :color="
                    detailAgenda.data.nama_kategori == 'Psikologi Klinis'
                      ? 'a__'
                      : detailAgenda.data.nama_kategori ==
                        'Psikologi Pendidikan'
                      ? 'b__'
                      : 'c__'
                  "
                  >{{ detailAgenda.data.nama_kategori }}</v-chip
                >
              </div>
              <v-row class="my-2">
                <v-col cols="12" md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        ><v-icon class="mr-1">mdi-map-marker</v-icon
                        >Location</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        detailAgenda.data.lokasi
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        ><v-icon class="mr-1">mdi-account-voice</v-icon
                        >Speaker</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        detailAgenda.data.pemateri
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        ><v-icon class="mr-1">mdi-earth</v-icon
                        >Facilities</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        detailAgenda.data.fasilitas
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" md="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        ><v-icon class="mr-1">mdi-alarm</v-icon
                        >Time</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        $date(detailAgenda.data.end_date).format("DD-MM-YYYY")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        ><v-icon class="mr-1">mdi-account-group</v-icon
                        >Kuota</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >{{ detailAgenda.data.kuota }}/{{
                          detailAgenda.data.kuota
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <h4>Description :</h4>
              <article>
                <div v-html="detailAgenda.data.deskripsi"></div>
              </article>
            </section>
            <h4>Poster :</h4>
            <v-img
              v-if="detailAgenda.data.poster"
              :src="`${env}/agenda/${detailAgenda.data.poster}`"
              class="img-fit"
            ></v-img>
          </v-card>
        </div>
      </div>
      <div v-if="loading" class="d-flex justify-center align-center">
        <h3>Loading...</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "viewAgenda",
  computed: {
    ...mapState({
      env: (state) => state.API_URL,
      detailAgenda: (state) => state.agenda.agendaDetail,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    getBack() {
      this.$router.go(-1);
    },
    fetch() {
      this.loading = true;
      let id = this.$route.params.id;
      this.$store.dispatch("agenda/AgendaById", id).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>
div >>> img {
  width: 100%;
}
.width__agenda {
  position: relative;
  width: 95%;
  box-sizing: border-box !important;
}
.container_detail {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
</style>
